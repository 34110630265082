import background from "../../images/background.jpg";
import {
  HeroContainer,
  HeroBg,
  BgOverlay,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowRight,
} from "./Hero.elements";
import { Button } from "../Button.elements";
import products from "../../pages/Data";

const HeroSection = () => {
  return (
    <HeroContainer id="home">
      <HeroBg image={background}>
        <BgOverlay color="#000000e0" />
      </HeroBg>
      <HeroContent>
        <HeroH1>PAM Innovations</HeroH1>
        <HeroP>Manufacturing. Distribution. Export.</HeroP>
        <HeroBtnWrapper>
          <Button
            to={products[0].id}
            primary="true"
            dark="true"
            smooth
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            See Our Products <ArrowRight />
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
