import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavDropdown,
  DropdownContent,
  DropdownLink,
} from "./Navbar.elements";
import { FaBars } from "react-icons/fa";
import { useEffect, useState } from "react";
import logo from "../../images/logofull.png";
import links from "../../pages/NavbarElements";
import products from "../../pages/Data";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollNav(window.scrollY >= 80);
    });
  }, [setScrollNav]);

  return (
    <Nav scrollNav={scrollNav}>
      <NavbarContainer>
        <NavLogo to="home" smooth="true" duration={500} spy={true} exact="true" offset={-80}>
          <img
            src={logo}
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              height: "100%",
              objectFit: "contain",
            }}
            alt="logo"
          />
        </NavLogo>
        <MobileIcon onClick={toggle}>
          <FaBars />
        </MobileIcon>
        <NavMenu>
          {links.map(({ to, text }) => {
            if (text === "Products") {
              return (
                <NavDropdown>
                  <NavLinks key={to} smooth="true" duration={500} spy={true} exact="true" offset={-80}>
                    {text}
                  </NavLinks>
                  <DropdownContent>
                    {products.map(({ id, headline }) => (
                      <DropdownLink
                        to={id}
                        key={id + headline}
                        smooth="true"
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                      >
                        {headline}
                      </DropdownLink>
                    ))}
                  </DropdownContent>
                </NavDropdown>
              );
            } else {
              return (
                <NavItem>
                  <NavLinks to={to} key={to} smooth="true" duration={500} spy={true} exact="true" offset={-80}>
                    {text}
                  </NavLinks>
                </NavItem>
              );
            }
          })}
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
