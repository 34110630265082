import { ContactContainer, FooterContainer, FooterWrapper, Heading, Text } from "./Footer.elements";

const Footer = ({ light }) => {
  return (
    <>
      <FooterContainer lightBg={light} id={"about"}>
        <FooterWrapper>
          <Heading>About Us</Heading>
          <Text darkText={light}>
            PAM Innovations is a 100% export-oriented organization established in the year 2021 as a sister concern of{" "}
            <b>Moldrite Innovations Pvt Ltd</b>.
            <br />
            <br />
            Moldrite Innovations Pvt Ltd is engaged in manufacturing, trading and exporting. We were selected as one of
            the “Top Ten Promising PPE Providers in India” by Silicon India magazine. To cater to our international
            clients with our best possible service, we commenced PAM Innovations. Our promoters have more than 30 years
            of experience in international commerce.
            <br />
            <br />
            Our vision is to provide world-class service to our customers, by constantly developing and upgrading our
            products while remaining true to our core values.
            <br />
            <br />
            Our mission is to make, distribute and export the highest quality products with a continued commitment to
            meet the market needs and standards. We concentrate our efforts on the individual requirements of our
            customers and help them strengthen their competitive position and performance.
            <br />
            <br />
            In 2023, we joined hands with a well-experienced and enthusiastic team and established{" "}
            <b>JMAT Trading LLC</b> registered office at Phoenix, Arizona, USA, with the objective of improving our
            customer service and exploring the market in the United States.
          </Text>
        </FooterWrapper>
      </FooterContainer>
      <FooterContainer lightBg={!light} id={"contact"}>
        <FooterWrapper>
          <Heading>Contact</Heading>
          <ContactContainer>
            <Text darkText={!light}>
              <b>PAM Innovations</b>
              <br />
              <br />
              Room No VIII-283/A,
              <br />
              Padinjare Matheikal Buildings,
              <br />
              Arakuzha - 686672, Kerala, India
              <br />
              Email:{" "}
              <a style={{ color: light ? "#3391ff" : null }} href="mailto: paminnova@gmail.com">
                paminnova@gmail.com
              </a>
              <br />
              Phone: +91 97452 37349, &nbsp;+91 98808 11339
            </Text>
            <Text darkText={!light}>
              <b>Moldrite Innovations Pvt Ltd</b>
              <br />
              <br />
              No. B-33, 2nd Cross,
              <br />
              3rd Stage, Peenya Industrial Area,
              <br /> Bangalore - 560 058, India
              <br /> Email:{" "}
              <a style={{ color: light ? "#3391ff" : null }} href="mailto: info@moldrite.in">
                info@moldrite.in
              </a>
              <br />
              Website:{" "}
              <a style={{ color: light ? "#3391ff" : null }} href="https://moldrite.in">
                moldrite.in
              </a>
              <br />
              Phone: +91 97452 37349, &nbsp;+91 98808 11339
            </Text>
            <Text darkText={!light}>
              <b>JMAT Trading LLC</b>
              <br />
              <br />
              2431 W. Bethany Home Rd.,
              <br />
              Phoenix, Arizona
              <br /> AZ 85015, USA
              <br /> Email:{" "}
              <a style={{ color: light ? "#3391ff" : null }} href="mailto: joshy.jmatusa@gmail.com">
                joshy.jmatusa@gmail.com
              </a>
              <br />
              Website:{" "}
              <a style={{ color: light ? "#3391ff" : null }} href="https://jmatusa.com">
                jmatusa.com
              </a>
              <br />
              Phone: 602-598-0931, +91 97452 3734
            </Text>
          </ContactContainer>
        </FooterWrapper>
      </FooterContainer>
    </>
  );
};

export default Footer;
