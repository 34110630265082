import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
} from "./Info.elements";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const ProductInfo = ({ id, imgStart, topLine, headline, imgs, description, light, alt }) => {
  return (
    <>
      <InfoContainer lightBg={light} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={!light}>{headline}</Heading>
                <Subtitle darkText={light}>{description}</Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
                  {imgs.map(({ src, id, caption }) => {
                    return (
                      <div key={id} style={{ height: "80%", width: "100%" }}>
                        <img
                          src={src}
                          alt={alt}
                          style={{ objectFit: "contain", width: "100%", height: "100%", maxHeight: "65vh" }}
                        />
                        {caption.length && <p className="legend">{caption}</p>}
                      </div>
                    );
                  })}
                </Carousel>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default ProductInfo;
