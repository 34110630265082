import jute_1 from "../images/jute_1.jpg";
import jute_2 from "../images/jute_2.jpg";
import jute_3 from "../images/jute_3.jpg";
import jute_4 from "../images/jute_4.jpg";
import plastic_1 from "../images/plastic_1.jpg";
import plastic_2 from "../images/plastic_2.jpg";
import plastic_3 from "../images/plastic_3.jpg";
import bags_1 from "../images/bags_1.jpg";
import bags_2 from "../images/bags_2.jpg";
import bags_3 from "../images/bags_3.jpg";
import bags_4 from "../images/bags_4.jpg";
import carton_1 from "../images/carton_1.jpg";
import carton_2 from "../images/carton_2.jpg";
import clothes_1 from "../images/clothes_1.jpg";
import clothes_2 from "../images/clothes_2.jpg";
import doormat_1 from "../images/doormat_1.jpg";
import doormat_2 from "../images/doormat_2.jpg";
import doormat_3 from "../images/doormat_3.jpg";
import slipper_1 from "../images/slipper_1.jpg";
import slipper_2 from "../images/slipper_2.jpg";
import slipper_3 from "../images/slipper_3.jpg";
import nonwoven_1 from "../images/nonwoven_1.png";
import nonwoven_2 from "../images/nonwoven_2.png";

const productData = [
  {
    id: "doormats",
    topLine: "Doormats",
    headline: "Doormats",
    description: "",
    imgs: [
      { id: "doormat_1", src: doormat_1, caption: "" },
      { id: "doormat_2", src: doormat_2, caption: "" },
      { id: "doormat_3", src: doormat_3, caption: "" },
    ],
    alt: "doormat_image",
  },
  {
    id: "rubber",
    topLine: "Rubber",
    headline: "Rubber Slippers",
    description: "",
    imgs: [
      { id: "slipper_1", src: slipper_1, caption: "" },
      { id: "slipper_2", src: slipper_2, caption: "" },
      { id: "slipper_3", src: slipper_3, caption: "" },
    ],
    alt: "rubber_image",
  },
  {
    id: "paper",
    topLine: "Paper",
    headline: "Paper Bags",
    description: "",
    imgs: [
      { id: "bags_1", src: bags_1, caption: "" },
      { id: "bags_2", src: bags_2, caption: "" },
      { id: "bags_3", src: bags_3, caption: "" },
      { id: "bags_4", src: bags_4, caption: "" },
    ],
    alt: "paper_image",
  },
  {
    id: "carton",
    topLine: "Cartons",
    headline: "Corrugated Cartons",
    description: "",
    imgs: [
      { id: "carton_1", src: carton_1, caption: "" },
      { id: "carton_2", src: carton_2, caption: "" },
    ],
    alt: "carton_image",
  },
  {
    id: "clothes",
    topLine: "Clothes",
    headline: "Pullovers, Hoodies & Tees",
    description: "",
    imgs: [
      { id: "clothes_1", src: clothes_1, caption: "" },
      { id: "clothes_2", src: clothes_2, caption: "" },
    ],
    alt: "cloth_image",
  },
  {
    id: "jutecotton",
    topLine: "Bags",
    headline: "Jute and Cotton Bags",
    description: "",
    imgs: [
      { id: "jute_1", src: jute_1, caption: "Hand Bags" },
      { id: "jute_2", src: jute_2, caption: "Gents Executive Bags" },
      { id: "jute_3", src: jute_3, caption: "Tiffin Bags" },
      { id: "jute_4", src: jute_4, caption: "Style Bags" },
    ],
    alt: "jute_image",
  },
  {
    id: "nonwovenbags",
    topLine: "Bags",
    headline: "Nonwoven Bags",
    description: (
      <>
        Virgin and Non-Virgin available.
        <br /> GSM, colour and size customisable.
      </>
    ),
    imgs: [
      { id: "nonwoven_1", src: nonwoven_1, caption: "D-Cut Bags" },
      { id: "nonwoven_2", src: nonwoven_2, caption: "U-Cut Bags" },
    ],
    alt: "bag_image",
  },
  {
    id: "plastic",
    topLine: "Plastic",
    headline: "Plastic Products",
    description: "",
    imgs: [
      { id: "plastic_1", src: plastic_1, caption: "" },
      { id: "plastic_2", src: plastic_2, caption: "" },
      { id: "plastic_3", src: plastic_3, caption: "" },
    ],
    alt: "plastic_image",
  },
];

let light = true;
let imgStart = true;

const products = [];
for (const pData of productData) {
  products.push({
    ...pData,
    light: light,
    imgStart: imgStart,
  });
  light = !light;
  imgStart = !imgStart;
}

export default products;
