import links from "../../pages/NavbarElements";
import { SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarLink } from "./Sidebar.elements";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarMenu>
        {links.map(({ to, text }) => (
          <SidebarLink
            to={to}
            key={to}
            smooth="true"
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            {text}
          </SidebarLink>
        ))}
      </SidebarMenu>
    </SidebarContainer>
  );
};

export default Sidebar;
