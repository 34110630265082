import { useCallback, useState } from "react";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
import Sidebar from "./../components/Sidebar";
import InfoSection from "./../components/InfoSection";
import products from "./Data";
import Footer from "../components/Footer";

const Home = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen]);

  return (
    <>
      <Sidebar isOpen={isSidebarOpen} toggle={toggleSidebar} />
      <Navbar toggle={toggleSidebar} />
      <HeroSection />
      {products.map((data) => (
        <InfoSection key={data.id} {...data} />
      ))}
      <Footer light={products.length % 2 === 0} />
    </>
  );
};

export default Home;
